footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
}

.footer {
    border-top: 2px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footer a {
    text-decoration: none;
    color: #000000;
}

.copyright {
    margin: 3px 0 0 0;
}