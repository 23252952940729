.Blog-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 2rem;
  border-bottom: 2px solid #847D7D;
  padding-bottom: 20px;
  margin-bottom: 16px;
  margin-top: 50px;
}

.Blog {
  position: relative;
  min-height: 100vh;
}

.Blog-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding-bottom: 100px;
}

.logo {
  font-family: "Playfair Display", sans-serif;
  font-size: 36px;
  padding-right: 8px;
}

@media (min-width: 480px) {
  .logo {
    width: 100px;
    font-size: 48px;
    padding-left: 12px;
  }
}

@media (min-width: 1024px) {
  .Blog {
    margin: 0 0;
  }
  .Blog-content{
    flex-direction: row;
  }
  .Blog-header {
    margin-bottom: 30px;
    height: 108px;
    margin-top: 0;
  }
}
