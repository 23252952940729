.read {
    width: 90%;
    margin: 0 auto;
    padding: 66px 0;
}

.read .heading {
  position: relative;
}

.read .title {
    font-size: 56px;
    margin: 0;
    font-weight: 300;
}

.read .date {
    font-size: 18px;
    margin-bottom: 20px;
}

.read .subtitle {
    font-size: 20px;
    margin: 20px 0 50px 0 ;
}

.read .body {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 20px;
}

.read .body hr {
    display: none;
}

.read .body a {
    color: #000000;
    cursor: pointer;
}

.read .body a:hover {
    color: grey;
}

.read .body h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: -10px;
    margin-top: 30px;
}

.read .body h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: -10px;
    margin-top: 30px;
}

.read .body h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: -10px;
    margin-top: 30px;
}

@media(max-width: 500px) {
    .mobileHeading {
        /* height: 80vh; */
        display: block;
        padding-bottom: 1px;
    }
    .read .subtitle {
        margin-top: 5vh;
    }
}

@media (min-width: 1024px) {
    .read {
        width: 60%;
        min-height: 500px;
        padding: 66px 10% 66px 0;
    }
    .read .heading {
        flex-wrap: nowrap;
    }
    .read .title:before {
        content:'';
        float: right;
        width: 150px;
        height: 56px;
    }
    .read .date {
        font-size: 16px;
        position: absolute;
        top: 0;
        right: 0;
    }
    .read .subtitle {
        margin: 10px 0 50px 0;
    }
    .read .body {
        margin-bottom: 0;
    }
}