.posts {
    width: 80%;
    margin: 0 auto;
    padding: 66px 0;
}

.postsHeading {
    font-size: 22px;
    padding-bottom: 15px;
}

@media (min-width: 1024px) {
    .posts {
        margin-top: 0;
        width: 17%;
        padding: 66px 4% 66px 9%;
    }
}

